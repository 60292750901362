<template>
  <section class="intro-info intro-info--security">
    <div class="container">
      <HeroTitle :info="titleInfo" />
    </div>
  </section>
</template>

<script>
import HeroTitle from '@/components/HeroTitle'

export default {
  name: 'IntroInfoSecurity',
  components: {HeroTitle},
  data: () => ({
    titleInfo: {
      image: require('@/assets/images/logo-background-dark.svg'),
      title: 'Физическая охрана объектов',
      subtitle: 'Охрана офисов, складских помещений и бизнес-центров – одна из основных мер для обеспечения ' +
        'безопасности Вашего бизнеса.'
    }
  })
}
</script>