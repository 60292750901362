<template>
  <PhysicalSecurityMain />
</template>

<script>
import PhysicalSecurityMain from '@/components/PhysicalSecurityMain'

export default {
  name: 'PhysicalSecurity',
  components: {PhysicalSecurityMain},
}
</script>