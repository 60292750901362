<template>
  <main>
    <IntroInfoSecurity />
    <CommonBlocksCards
      title="Типы охраны"
      :blocksInfo="securityTypes"
    />
    <Tariffs :tariffs="tariffsList" />
  </main>
</template>

<script>
import IntroInfoSecurity from '@/components/Sections/IntroInfoSecurity'
import CommonBlocksCards from '@/components/CommonBlocksCards'
import Tariffs from '@/components/Sections/Tariffs'

export default {
  name: 'PhysicalSecurityMain',
  components: {
    Tariffs,
    CommonBlocksCards,
    IntroInfoSecurity
  },
  data: () => ({
    securityTypes: [
      {
        img: require('@/assets/images/security-types/image-01.png'),
        title: ' Личная охрана',
        description: 'Обеспечение безопасности и сохранности недвижимого имущества на охраняемых стационарных объектах',
      },
      {
        img: require('@/assets/images/security-types/image-02.png'),
        title: 'Охрана мероприятий',
        description: 'Обеспечение безопасности и сохранности недвижимого имущества на охраняемых стационарных объектах',
      },
      {
        img: require('@/assets/images/security-types/image-03.png'),
        title: 'Охрана грузоперевозок',
        description: 'Обеспечение безопасности и сохранности недвижимого имущества на охраняемых стационарных объектах',
      },
    ],
    tariffsList: [
      {
        tariffName: 'Бюджет',
        tariffPrice: '₴39,59',
        tariffsTime: 'за час',
        skills: ['Опыт работы в охране от 1-го года', 'Наличие физической подготовки Знание основ законодательства'],
        categories: ['Склады', 'Производственные объекты', 'Строительные площадки', 'Удаленные объекты'],
        pricePerMonth: '₴28 900',
        link: '#'
      },
      {
        tariffName: 'Стандарт',
        tariffPrice: '₴48,30',
        tariffsTime: 'за час',
        skills: ['Опыт работы в охране от 3-х лет',
          'Хорошая физическая подготовка Знание нормативно-правовой базы Исполнение админ.', 'Наличие спецсредств по охране;'],
        categories: ['Офисы', 'Торговые центры / Магазины', ' Частные резиденции', 'Загородные дома / Коттеджи', 'Гос. организации Предприятия'],
        pricePerMonth: '₴33 800',
        link: '#'
      },
      {
        tariffName: 'Премиум',
        tariffPrice: '₴80,54',
        tariffsTime: 'за час',
        skills: ['Опыт работы в охране от 5-ти лет', 'Разрешение на оружие', 'Знание иностранных языков'],
        categories: ['Объекты повышенного риска', 'Посольства Иностранные представительства', 'Отели', 'Торговые центры'],
        pricePerMonth: '₴58 000',
        link: '#'
      },
    ]
  })
}
</script>