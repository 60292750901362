<template>
  <section class="tariffs">
    <div class="container">
      <h3 class="tariffs__section-title section-title">Тарифы</h3>

      <ul class="tariffs__card-list">
        <li
          v-for="(item, index) in tariffs"
          :key="index"
          class="tariffs__card-item"
        >
          <div>
            <div class="tariffs__card-header">
              <h5 class="tariffs__tariff-name">{{ item.tariffName }}</h5>

              <p class="tariffs__price-wrapper">
                <span class="tariffs__price">{{ item.tariffPrice }}</span>
                <span class="tariffs__time">{{ item.tariffsTime }}</span>
              </p>
            </div>

            <ul class="tariffs__skills-list">
              <li
                v-for="(skill, index) in item.skills"
                :key="index"
                class="tariffs__skills-item"
              >
                {{ skill }}
              </li>
            </ul>

            <div class="tariffs__categories">
              <h6 class="tariffs__categories-title">Объекты категории:</h6>

              <ul class="tariffs__categories-list">
                <li
                  v-for="(categories, index) in item.categories"
                  :key="index"
                  class="tariffs__categories-item"
                >
                  {{ categories }}
                </li>
              </ul>
            </div>
          </div>

          <div class="tariffs__tariff-btn-wrapper">
            <a
              :href="item.link"
              class="tariffs__choose-tariff-btn btn btn--white"
            >
              от  {{ item.pricePerMonth }}  за месяц
            </a>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tariffs',
  props: {
    tariffs: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>